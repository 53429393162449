@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
  }

  html {
    @apply flex h-full min-h-full flex-col bg-neutral-0;
  }

  body {
    @apply flex h-full min-h-full flex-col bg-neutral-0 text-neutral-900;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    @apply min-h-full;
  }

  ::-webkit-scrollbar {
    appearance: none;
    width: 16px;
    min-height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    @apply border-4 border-solid border-transparent bg-neutral-100;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply border-4 border-solid border-transparent bg-neutral-200;
  }

  /* Set the background color of the scrollbar */
  ::-webkit-scrollbar-track {
    width: 16px;
    background-color: transparent;
  }

  /* Keep the scrollbar always visible */
  ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px !important;
  }
}

/* TODO: Better to move it to the @layer, but currently it brakes autocomplete */
.typography-h1 {
  @apply text-8xl/[6.25rem] font-bold;
}

.typography-h2 {
  @apply text-6xl/[4rem] font-bold;
}

.typography-h3 {
  @apply text-5xl font-semibold;
}

.typography-h4 {
  @apply text-3xl font-semibold;
}

.typography-h5 {
  @apply text-2xl font-semibold;
}

.typography-h6 {
  @apply text-xl font-semibold;
}

.typography-subtitle1 {
  @apply text-base font-semibold;
}

.typography-subtitle2 {
  @apply text-sm font-semibold;
}

.typography-body1 {
  @apply text-base font-normal;
}

.typography-body2 {
  @apply text-sm font-normal;
}

.typography-paragraph1 {
  @apply text-lg font-normal;
}

.typography-paragraph2 {
  @apply text-base/5 font-normal;
}

.typography-button-m {
  @apply text-sm font-medium;
}

.typography-link {
  @apply text-lg font-normal text-primary-500 underline;
}

.typography-strong {
  @apply text-base font-bold text-primary-500;
}

.typography-label {
  @apply text-xs/3  font-medium uppercase tracking-[1px];
}

.typography-menu-label {
  font-size: 0.725rem;
  line-height: 0.875rem;
}

.typography-caption {
  @apply text-xs/[0.875rem] font-normal;
}

.typography-tooltip {
  @apply font-medium;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

/*shadows*/
.menu-shadow {
  box-shadow: 0 -4px 8px 0 #0000000a, 0 -15px 15px 0 #0000000a, 0 -34px 21px 0 #0000000a, 0 -62px 24px 0 #00000003;
}

.selected-element-buttons-panel-shadow {
  box-shadow: 0px 8px 8px 0px rgba(25, 25, 25, 0.04), 0px 16px 10px 0px rgba(25, 25, 25, 0.04);
}

/* fix black line between arrow and body. floaterProps didn't help */
.__floater__body {
  margin: -1px;
}

#hubspotForm input[type='text'],
#hubspotForm input[type='email'] {
  @apply typography-body1   flex w-full rounded-md  border border-neutral-300 bg-neutral-0 px-3 py-2  placeholder:text-neutral-300  focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50;
  @apply hover:border-neutral-500;
  @apply focus:border-neutral-800;
  @apply focus:outline-none;
}

#hubspotForm input[type='text'].error,
#hubspotForm input[type='email'].error {
  @apply border-primary-300;
}
#hubspotForm .field {
  @apply relative mb-6  w-full space-y-2;
}

#hubspotForm .field label {
  @apply typography-label  text-neutral-900 group-disabled:text-neutral-300 peer-disabled:cursor-not-allowed group-aria-disabled:text-neutral-300;
}

#hubspotForm .field:has(.error) label {
  @apply text-primary-300;
}

#hubspotForm .hs-error-msgs {
  @apply text-sm font-medium text-primary-200;
}

#hubspotForm .hs_firstname,
#hubspotForm .hs_lastname {
  @apply w-full sm:w-[calc(50%-0.5rem)];
}
#hubspotForm .hs_firstname {
  @apply sm:mr-4;
}

#hubspotForm form {
  @apply flex flex-wrap;
}

#hubspotForm label.hs-form-booleancheckbox-display {
  @apply mb-6 text-xs/[0.875rem] font-normal normal-case tracking-normal text-neutral-600;
}

#hubspotForm .hs-richtext {
  @apply text-xs/[0.875rem] font-normal text-neutral-600;
}

#hubspotForm .hs-richtext p {
  @apply mb-2;
}

#hubspotForm .hs-submit {
  @apply mt-6 w-full text-center;
}
#hubspotForm .hs-button {
  @apply inline-flex cursor-pointer items-center justify-center gap-2 rounded border-2 border-primary-500 bg-primary-500 px-6 py-3 text-sm font-medium uppercase text-neutral-0 transition-colors hover:border-primary-800 hover:bg-primary-800 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 active:border-primary-900 active:bg-primary-900 disabled:pointer-events-none disabled:opacity-50;
}

#hubspotForm .hs-error-msgs {
  @apply hidden;
}

#hubspotForm a {
  @apply text-secondary-500 underline;
}

.App {
  scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
  appearance: none;
}
